export default {
  SET_ACOS_ROAS_SELECT(state, payload) {
    state.acosRoasSelect = payload;
  },
  SET_TACOS_TACOP_TPRAS_SELECT(state, payload) {
    state.tacosTacopTprasSelect = payload;
  },
  SET_ADVERTISING_SALES_ADVERTISING_ORDERS_SELECT(state, payload) {
    state.advertisingSalesAdvertisingOrdersSelect = payload;
  },
  SET_ORGANIC_SALES_ORGANIC_ORDERS_SELECT(state, payload) {
    state.organicSalesOrganicOrdersSelect = payload;
  },
  SET_TOTAL_SALES_TOTAL_ORDERS_SELECT(state, payload) {
    state.totalSalesTotalOrdersSelect = payload;
  },
  SET_ACTIVE_MAIN_PREFERENCE_KEY_LIST(state, payload) {
    if (state.activeMainPreferenceKeyList.includes(payload)) {
      state.activeMainPreferenceKeyList =
        state.activeMainPreferenceKeyList.filter((item) => item !== payload);
    } else {
      state.activeMainPreferenceKeyList.push(payload);
    }
    localStorage.setItem(
      'activeMainPreferenceKeyList',
      JSON.stringify(state.activeMainPreferenceKeyList)
    );
  },
  SET_ACTIVE_ADV_PREFERENCE_KEY_LIST(state, payload) {
    if (state.activeAdvPreferenceKeyList.includes(payload)) {
      state.activeAdvPreferenceKeyList =
        state.activeAdvPreferenceKeyList.filter((item) => item !== payload);
    } else {
      state.activeAdvPreferenceKeyList.push(payload);
    }
    localStorage.setItem(
      'activeAdvPreferenceKeyList',
      JSON.stringify(state.activeAdvPreferenceKeyList)
    );
  },
};
