export default {
  getAcosRoasSelect: (state) => state.acosRoasSelect,
  getTacosTacopTprasSelect: (state) => state.tacosTacopTprasSelect,
  getAdvertisingSalesAdvertisingOrdersSelect: (state) =>
    state.advertisingSalesAdvertisingOrdersSelect,
  getOrganicSalesOrganicOrdersSelect: (state) =>
    state.organicSalesOrganicOrdersSelect,
  getTotalSalesTotalOrdersSelect: (state) => state.totalSalesTotalOrdersSelect,
  getActiveMainPreferenceKeyList: (state) => state.activeMainPreferenceKeyList,
  getActiveAdvPreferenceKeyList: (state) => state.activeAdvPreferenceKeyList,
};
