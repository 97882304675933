const isRuleResultsFilterReady = false;
const activeRuleTab = 'bidRules';
const ruleResultsTableData = {
  rows: [],
  currency: '',
  sortingKey: 'calculation_time',
  sortingType: 'asc',
};

const ruleResultsTableConfig = {
  activePage: 1,
  hasNextPage: false,
  isNextPageButtonDisabled: false,
  hasPrevPage: false,
  isPrevPageButtonDisabled: false,
};

const ruleResultsPageSize = '50';

const ruleResultFilters = [{ field: 'TARGETING', values: 'ASIN' }];

const ruleResultDetail = {
  isPopupActive: false,
  selectedEntity: {},
};

const tableData = {
  rows: [],
  total: {},
  currency: '',
};

const tableConfig = {
  activePage: 1,
  hasNextPage: false,
  isNextPageButtonDisabled: false,
  hasPrevPage: false,
  isPrevPageButtonDisabled: false,
};

const historyTableConfig = {
  activePage: 1,
  hasNextPage: false,
  isNextPageButtonDisabled: false,
  hasPrevPage: false,
  isPrevPageButtonDisabled: false,
};

const filter = {
  'query.filters': [],
};

const filterPreSelect = {
  portfolio: [],
  campaign: [],
  adGroup: [],
  target: [],
  searchTerm: [],
};

const sort = {
  'query.sortingKey': 'sales',
  'query.sortingType': 'desc',
};

const dateRange = {
  startDate: null,
  endDate: null,
};

const dashboardDateRange = null;

const dashboardDateRangeType = null;

const advertisingAvailableDateRange = null;
const pageInfo = {
  'query.pageSize':
    parseInt(
      JSON.parse(localStorage.getItem('advertisingPageOptions'))?.pageInfo?.[
        'query.pageSize'
      ]
    ) || 50,
};

const historyPageInfo = {
  'query.pageSize': 20,
};

const activeTab = localStorage.getItem('activeTab') || 'campaign';

const serviceNotify = {
  show: false,
};

const toolbarData = {
  isAllPageSelected: false,
  isAllDataSelected: false,
  selectedPortfolios: [],
  selectedCampaigns: [],
  selectedAdGroups: [],
  selectedTargets: [],
  selectedProducts: [],
  productItemsHistory: [],
};

const toolbarOptions = {
  activeProductUpdateOption: 'productOfCampaign',
};

const updateInformation = {
  successCount: null,
  errorCount: null,
  errorList: [],
};

const pageOptions = {
  useStickyColumn: true,
};

const automation = {
  isPopupActive: false,
  currentStep: 0,
  selectedItems: [],
  selectedRules: [],
};

const additionalFeatures = {
  letAdditionalFeatures: false,
  showAdditionalFeaturesPopup: false,
};

const updateHistory = {
  isPopupActive: false,
  entityTypeString: null,
  ruleTypeString: null,
  entityId: null,
  data: [],
  entity: {},
};

const dashboardData = {
  currency: null,
  LastPeriodDifference: {
    Acos: {},
    AdOrders: {},
    AdSales: {},
    AdSpend: {},
    Ctr: {},
    Cvr: {},
    OrganicOrders: {},
    OrganicSales: {},
    OtherCost: {},
    Tacos: {},
    TotalCost: {},
    TotalOrders: {},
    TotalSales: {},
    Impressions: {},
    Clicks: {},
  },
  LastPeriodChart1: null,
  LastPeriodChart2: null,
  TableHeaders: [],
  PeriodHeaders: [],
  TablePeriods: [],
  TableChart: [],
  selectedPeriod: 'Weekly',
  lastSyncTime: null,
  conversionRate: null,
  ordersAndPerformance: null,
  clickSessionsAndCtr: null,
  averageOrderValue: null,
  salesSpendAndPerformance: null,
  extraCostData: null,
  periodDates: null,
  LastPeriodByCampaignType: {
    current: {},
    previous: {},
  },
};
const forecastRequestStatus = null;
const forecastData = null;

const analyticsMetrics = null;
const analyticsChartData = null;

const ProductLevelSettings = {
  selectedDetail: null,
  filter: {},
  searchText: '',
};

const selectedAnalyticsSmartFilter = null;

const analyticsSmartFilters = {
  campaign: [],
  adGroup: [],
  target: [],
  product: [],
};

const advDataStatus = '';

const dashboardTrendReportTab = {
  kpiChanges: {
    content: '',
    _content: '',
    disabled: false,
  },
  AdvKpiChanges: {
    content: '',
    _content: '',
    disabled: false,
  },
  actionsTaken: {
    content: '',
    _content: '',
    disabled: false,
  },
  plan: {
    content: '',
    _content: '',
    disabled: false,
  },
};

const trendReportdateRange = {
  startDate: null,
  endDate: null,
};

const trendReportChartOptions = {
  performanceMetrics: null,
  campaignTypeColumn: null,
  campaignTypePerformance: null,
  adSalesPie: null,
  adSpendPie: null,
  profitTrend: null,
  netProfit: null,
  acos: null,
  totalSales: null,
};

const appActiveStoreTrial = false;
const advertisingAnalyticsAvaliableDateRange = null;
const advAnalyticsDateRangeType = null;
const ruleResultAvaliableDateRange = null;
const ruleResultDateRangeType = null;

const specialDaysStatus = true;
const analyticsSpecialDays = null;

const SearchTerm = {
  filter: {},
  searchText: '',
};
export default {
  isRuleResultsFilterReady,
  activeRuleTab,
  ruleResultsTableData,
  ruleResultsTableConfig,
  ruleResultsPageSize,
  ruleResultFilters,
  ruleResultDetail,
  tableData,
  tableConfig,
  historyTableConfig,
  filter,
  filterPreSelect,
  sort,
  dateRange,
  dashboardDateRange,
  dashboardDateRangeType,
  pageInfo,
  historyPageInfo,
  activeTab,
  serviceNotify,
  toolbarData,
  toolbarOptions,
  updateInformation,
  pageOptions,
  automation,
  additionalFeatures,
  updateHistory,
  dashboardData,
  ProductLevelSettings,
  advDataStatus,
  forecastRequestStatus,
  forecastData,
  advertisingAvailableDateRange,
  analyticsSmartFilters,
  selectedAnalyticsSmartFilter,
  analyticsChartData,
  dashboardTrendReportTab,
  trendReportdateRange,
  trendReportChartOptions,
  appActiveStoreTrial,
  advertisingAnalyticsAvaliableDateRange,
  advAnalyticsDateRangeType,
  ruleResultAvaliableDateRange,
  ruleResultDateRangeType,
  specialDaysStatus,
  analyticsSpecialDays,
  SearchTerm,
};
