export default {
  acosRoasSelect: 'ACoS',
  tacosTacopTprasSelect: 'TACoS',
  advertisingSalesAdvertisingOrdersSelect: 'Advertising Sales',
  organicSalesOrganicOrdersSelect: 'Organic Sales',
  totalSalesTotalOrdersSelect: 'Total Sales',
  activeMainPreferenceKeyList:
    JSON.parse(localStorage.getItem('activeMainPreferenceKeyList')) || [],
  activeAdvPreferenceKeyList:
    JSON.parse(localStorage.getItem('activeAdvPreferenceKeyList')) || [],
};
