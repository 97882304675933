export default {
  changeIsRuleResultsFilterReady({ commit }, payload) {
    commit('SET_RULERESULTSFILTERREADY', payload);
  },

  changeRuleResultsTableData({ commit }, payload) {
    commit('SET_RULERESULTSTABLEDATA', payload);
  },
  setActiveRuleTab({ commit }, payload) {
    commit('SET_ACTIVE_RULE_TAB', payload);
  },

  changeRuleResultsTableConfig({ commit }, payload) {
    commit('SET_RULERESULTSTABLECONFIG', payload);
  },

  changeRuleResultsPageSize({ commit }, payload) {
    commit('SET_RULERESULTSPAGESIZE', payload);
  },

  changeRuleResultFilters({ commit }, payload) {
    commit('SET_RULERESULTDETAILS', payload);
  },

  changeRuleResultDetail({ commit }, payload) {
    commit('SET_RULERESULTDETAIL', payload);
  },

  changeTableData({ commit }, payload) {
    commit('SET_TABLEDATA', payload);
  },

  changeTableConfig({ commit }, payload) {
    commit('SET_TABLECONFIG', payload);
  },

  changeHistoryTableConfig({ commit }, payload) {
    commit('SET_HISTORYTABLECONFIG', payload);
  },

  changeAdvertisingAccountStatus({ commit }, payload) {
    commit('SET_ADVERTISINGACCOUNTSTATUS', payload);
  },

  changeFilter({ commit }, payload) {
    commit('SET_FILTER', payload);
  },

  changeFilterPreSelect({ commit }, payload) {
    commit('SET_FILTERPRESELECT', payload);
  },

  changeSort({ commit }, payload) {
    commit('SET_SORT', payload);
  },

  changeDateRange({ commit }, payload) {
    commit('SET_DATERANGE', payload);
  },

  setDashboardDateRange({ commit }, payload) {
    commit('SET_DASHBOARD_DATERANGE', payload);
  },

  setDashboardDateRangeType({ commit }, payload) {
    commit('SET_DASHBOARD_DATERANGE_TYPE', payload);
  },

  changePageInfo({ commit }, payload) {
    commit('SET_PAGEINFO', payload);
  },

  changeHistoryPageInfo({ commit }, payload) {
    commit('SET_HISTORYPAGEINFO', payload);
  },

  changeActiveTab({ commit }, payload) {
    commit('SET_ACTIVETAB', payload);
  },

  changeServiceNotify({ commit }, payload) {
    commit('SET_SERVICENOTIFY', payload);
  },

  changeToolbarData({ commit }, payload) {
    commit('SET_TOOLBARDATA', payload);
  },

  changeToolbarOptions({ commit }, payload) {
    commit('SET_TOOLBAROPTIONS', payload);
  },

  changeUpdateInformation({ commit }, payload) {
    commit('SET_UPDATEINFORMATION', payload);
  },

  changePageOptions({ commit }, payload) {
    commit('SET_PAGEOPTIONS', payload);
  },

  changeAutomation({ commit }, payload) {
    commit('SET_AUTOMATION', payload);
  },

  changeAdditionalFeatures({ commit }, payload) {
    commit('SET_ADDITIONALFEATURES', payload);
  },

  changeUpdateHistory({ commit }, payload) {
    commit('SET_UPDATEHISTORY', payload);
  },

  setCurrency({ commit }, payload) {
    commit('SET_CURRENCY', payload);
  },

  setLastPeriodDifference({ commit }, payload) {
    commit('SET_LAST_PERIOD_DIFFERENCE', { ...payload });
  },

  setLastPeriodByCampaignType({ commit }, payload) {
    commit('SET_LAST_PERIOD_BY_CAMPAING_TYPE', { ...payload });
  },

  setTableHeaders({ commit }, payload) {
    commit('SET_TABLE_HEADERS', payload);
  },

  setPeriodHeaders({ commit }, payload) {
    commit('SET_PERIOD_HEADERS', payload);
  },

  setTablePeriods({ commit }, payload) {
    commit('SET_TABLE_PERIODS', payload);
  },

  setTableChart({ commit }, payload) {
    commit('SET_TABLE_CHART', payload);
  },

  setSelectedPeriod({ commit }, payload) {
    commit('SET_SELECTED_PERIOD', payload);
  },

  setLastSyncTime({ commit }, payload) {
    commit('SET_LAST_SYNC_TIME', payload);
  },

  setConversionRate({ commit }, payload) {
    commit('SET_CONVERSION_RATE', payload);
  },

  setOrdersAndPerformance({ commit }, payload) {
    commit('SET_ORDERS_AND_PERFORMANCE', payload);
  },

  setClickSessionsAndCtr({ commit }, payload) {
    commit('SET_CLICK_SESSIONS_AND_CTR', payload);
  },

  setAverageOrderValue({ commit }, payload) {
    commit('SET_AVERAGE_ORDER_VALUE', payload);
  },

  setSalesSpendAndPerformance({ commit }, payload) {
    commit('SET_SALES_SPEND_AND_PERFORMANCE', payload);
  },

  setPeriodDates({ commit }, payload) {
    commit('SET_PERIOD_DATES', payload);
  },

  closeProductLevelSettingsDetail({ commit }, payload) {
    commit('SET_PRODUCT_LEVEL_SETTINGS_DETAIL', payload);
  },

  setProductLevelSettingsFilter({ commit }, payload) {
    commit('SET_PRODUCT_LEVEL_SETTINGS_FILTER', payload);
  },

  removeProductLevelSettingsFilter({ commit }, payload) {
    commit('REMOVE_PRODUCT_LEVEL_SETTINGS_FILTER', payload);
  },

  setProductLevelSettingsSearchText({ commit }, payload) {
    commit('SET_PRODUCT_LEVEL_SETTINGS_FILTER_SEARCH_TEXT', payload);
  },
  setAdvDataStatus({ commit }, payload) {
    commit('SET_ADV_DATA_STATUS', payload);
  },

  setForecastRequestStatus({ commit }, payload) {
    commit('SET_FORECAST_REQUEST_STATUS', payload);
  },

  setForecastData({ commit }, payload) {
    commit('SET_FORECAST_DATA', payload);
  },

  setDashboardAvailableDateRange({ commit }, payload) {
    commit('SET_DASHBOARD_AVAILABLE_DATE_RANGE', payload);
  },

  setAnalyticsSmartFilters({ commit }, payload) {
    commit('SET_ANALYTICS_SMART_FILTERS', payload);
  },

  setSelectedAnalyticsSmartFilters({ commit }, payload) {
    commit('SET_SELECTED_ANALYTICS_SMART_FILTERS', payload);
  },

  setAnalyticsChartData({ commit }, payload) {
    commit('SET_ANALYTICS_CHART_DATA', payload);
  },

  setDashboardTrendReportTab({ commit }, payload) {
    commit('SET_DASHBOARD_TREND_REPORT_TAB', payload);
  },

  changeTrendReportDateRange({ commit }, payload) {
    commit('SET_TRENDREPORT_DATERANGE', payload);
  },

  changeTrendReportOptions({ commit }, payload) {
    commit('SET_TRENDREPORT_OPTIONS', payload);
  },

  changeAppActiveStoreTrial({ commit }, payload) {
    commit('SET_APP_ACTIVE_STORE_TRIAL', payload);
  },

  setAdvertisingAnalyticsAvaliableDateRange({ commit }, payload) {
    commit('SET_ADVERTISING_ANALYTICS_AVALIABLE_DATE_RANGE', payload);
  },

  setAdvAnalyticsDateRangeType({ commit }, payload) {
    commit('SET_ADV_ANALYTICS_DATE_RANGE_TYPE', payload);
  },

  setRuleResultAvaliableDateRange({ commit }, payload) {
    commit('SET_RULE_RESULT_AVALIABLE_DATE_RANGE', payload);
  },

  setRuleResultDateRangeType({ commit }, payload) {
    commit('SET_RULE_RESULT_DATE_RANGE_TYPE', payload);
  },

  setSpecialDaysStatus({ commit }, payload) {
    commit('SET_SPECIAL_DAYS_STATUS', payload);
  },
  setAnalyticsSpecialDays({ commit }, payload) {
    commit('SET_ANALYTICS_SPECIAL_DAYS', payload);
  },

  setSearchTermFilter({ commit }, payload) {
    commit('SET_SEARCH_TERM_FILTER', payload);
  },

  setSearchTermSearchText({ commit }, payload) {
    commit('SET_SEARCH_TERM_SEARCH_TEXT', payload);
  },
};
